<template>
  <div>
    <reports-table
      tableName="Reporte detallado"
      :isDetailedReport="true"
      :permission="{
        downloadPermission: permissions.DOWNLOAD_DETAILED_REPORT,
      }"
    ></reports-table>
  </div>
</template>

<script>
import ReportsTable from "../../../../components/Administration/ReportsTable.vue";
import { permissions } from "../../../../enum/applicationPermissions";
export default {
  components: {
    ReportsTable,
  },
  data() {
    return {
      permissions: permissions,
    };
  },
};
</script>

<style scoped></style>
